import React from "react"
import { Helmet } from "react-helmet"
import Layout, { Headline } from "../../components/layout"
import CarouselTestimonials from "../../components/carousels/testimonials"
import Video from "../../components/video/video"
import bg from "../../assets/img/0329.jpg"
import AudioPlayer, {
  AudioCopy,
  AudioInfoLink,
  AudioTitle,
} from "../../components/audioPlayer/audioPlayer"
import styled from "styled-components"
import AsSeenOn from "../../components/asSeenOn/asSeenOn"
import VsTable from "../../components/vsTable/vsTable"

const AudioPlayers = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 40px;
  padding-bottom: 40px;
`

export default function Reviews() {
  return (
    <Layout
      bg={bg}
      header={"Show Us Any Competitor's Written Offer and We'll Beat It!"}
      subheader={""}
    >
      <Helmet>
        <title>Reviews : Direct Buyers</title>
        <meta name="description" content="Direct Buyers is making waves." />
      </Helmet>

      <div className={`container`}>
        <Headline>
          <h1>Reviews</h1>
        </Headline>
        <AudioPlayers>
          <AudioPlayer fileUrl="/91220-KFI-831amBillHandelLive60.mp3">
            <AudioTitle>Bill Handel</AudioTitle>
            <AudioCopy>
              Listen to what Top Talk Radio personality Bill Handel of KFI 640AM
              "Handel on the Law Show" had to say about Direct Buyers &mdash;{" "}
              <AudioInfoLink
                target="_blank"
                href="https://kfiam640.iheart.com/featured/bill-handel"
              >
                Bill Handel Saturday Mornings
              </AudioInfoLink>
            </AudioCopy>
          </AudioPlayer>
          <AudioPlayer fileUrl="/e2.mp3">
            <AudioTitle>Ellen K.</AudioTitle>
            <AudioCopy>
              Listen to what Top Radio personality, Ellen K. of KOST 103.5 had
              to say about Direct Buyers &mdash;{" "}
              <AudioInfoLink target="_blank" href="https://ellenk.com/about/">
                More about Ellen K.
              </AudioInfoLink>
            </AudioCopy>
          </AudioPlayer>
        </AudioPlayers>

        <div className={`container`}>
          <div className="row">
            <div className="col-md-6">
              <Video url={"https://www.youtube.com?v=duHrB_4JRa4"} />
              <AudioCopy>
                Listen to&nbsp;
                <AudioInfoLink
                  target="_blank"
                  href="https://kfiam640.iheart.com/featured/bill-handel"
                >
                  Bill Handel Saturday Mornings
                </AudioInfoLink>
              </AudioCopy>
            </div>
            <div className="col-md-6">
              <Video url={"https://www.youtube.com&v=5tQgEQAMCzE"} />
              <AsSeenOn />
            </div>
          </div>

          <Headline>
            <h2 style={{ fontSize: "unset" }}>
              Selling to Direct Buyers vs. the Traditional Selling Process
            </h2>
          </Headline>
          <div className={`row`}>
            <div className="col">
              <VsTable />
            </div>
          </div>
        </div>

        <CarouselTestimonials />
      </div>
    </Layout>
  )
}
