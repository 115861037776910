import React from "react";
import Carousel from "react-bootstrap/cjs/Carousel";
import styled from "styled-components";
import {StyledCarousel, Headline} from "../layout";
import mq from "../../styles/media-query";

const TestimonialContent = [
    {
        quo: "I could not have asked for a better experience than that which I received in my recent transaction with Direct Buyers. They were always  courteous, flexible, reliable, and accessible. More importantly, they continue to demonstrate the utmost in integrity and honesty. Their communications with me have always been clear, and they stick to their word. I would have no hesitation in doing business with this company again...",
        att: "Frank N"
    },
    {
        quo: "I received a letter in the mail stating they would be interested in purchasing my property. It was good timing because I was wanting to sell the property in LA County. I called and he came out at my convenience. A fair offer was accepted. The problem was that I still needed a good three months to finish going through the house packing, tossing and giving away items. We signed paperwork for a future date that was acceptable to me. The date came, they bought the property and I received the money. The house was a fixer upper and they did a nice job of remodeling it as I was able to go back and see what they had done. Smooth transaction.",
        att: "Annie H"
    },
    {
        quo: "He responded quickly to my call and was willing to meet me at my convenience. He gave me an acceptable offer at our first meeting and had all the paperwork needed to begin the process. He was courteous and knowledgeable to work with and our transaction was completed easily and in a short amount of time. I highly recommend contacting Direct Buyers.",
        att: "William B"
    },
    {
        quo: "Our company just completed a project with one the companies associates and it was such a smooth transaction. Thank you for your professionalism, it was a pleasure. We look forward to working with your company and associates again soon!",
        att: "S. Compass"
    },
    {
        quo: "The business you can truly trust! Thanks to them a few of our clients were able to get what they needed, Direct Buyers is a great organization to deal with, kind and very knowledgeable. I send everyone to them who needs help and guidance when moving on from a property. Best offers and they are super fair.",
        att: "Christopher M"
    },
    {
        quo: "I called Direct Buyers for an offer and compared to other people who sent me mail, their offer was the best. They were always available when I called. The process was easy and we closed when I was ready.",
        att: "William"
    },
    {
        quo: "Working with Direct Buyers has been a pleasure, with a win/win outcome for all parties. They are professional, fair, honest, ethical and responsive. No delays, games and or hidden agendas. I have been a Realtor® for over 15 years and working with them has been a very positive, smooth experience. I hope to have the opportunity to continue working with them in the years ahead!",
        att: "K. Dee"
    },
    {
        quo: "I would confidently recommend Direct Buyers to do business with. I sold them two fixer-uppers, one last year and one this year. They were always concerned that everything was going ok for me. I couldn't be more pleased with the process and my experience with",
        att: "Arnold"
    },
    {
        quo: "Direct Buyers were a pleasure to work with. Very professional and extremely helpful.",
        att: "Joan"
    },
    {
        quo: "They were honest, thorough and enabled the sale of our home quickly. We highly recommend them and Thank them.",
        att: "Martha B"
    },
]

const shuffleArray = (array) => {
    let i = array.length - 1;
    for (; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        const temp = array[i];
        array[i] = array[j];
        array[j] = temp;
    }
    return array;
}

const chosenOnes = shuffleArray(TestimonialContent);

const CaroItem = styled.div`

    >div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        
        &:not(:first-child) {
            margin-top: 3rem;
            ${mq.lg`
                margin-top: 0;
            `}
        }
        img {
            height: auto;
            width: 33%;
        }
        em {
            font-size: 90%;
            color: var(--blueGrey);
        }
    }
`;

export default function CarouselTestimonials() {
    return (
        <StyledCarousel>
            <Headline><h3>Testimonials</h3></Headline>
            <Carousel>
                <Carousel.Item>
                    <CaroItem className={`row`}>
                        <div className={`col-md-6`}>
                            <p>{chosenOnes[0].quo}</p>
                            <em>{chosenOnes[0].att}</em>
                        </div>
                        <div className={`col-md-6`}>
                            <p>{chosenOnes[1].quo}</p>
                            <em>{chosenOnes[1].att}</em>
                        </div>
                    </CaroItem>
                </Carousel.Item>
                <Carousel.Item>
                    <CaroItem className={`row`}>
                        <div className={`col-md-6`}>
                            <p>{chosenOnes[2].quo}</p>
                            <em>{chosenOnes[2].att}</em>
                        </div>
                        <div className={`col-md-6`}>
                            <p>{chosenOnes[3].quo}</p>
                            <em>{chosenOnes[3].att}</em>
                        </div>
                    </CaroItem>
                </Carousel.Item>
            </Carousel>
        </StyledCarousel>
    )
}